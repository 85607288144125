details>summary {
    list-style: none;
    font-weight: 400;
    font-size: larger;
    padding: 2vh 4vw;
}

details>summary::-webkit-details-marker {
    display: none;
}

a {
    text-decoration: none;
    color: #393E46;
}

a:hover {
    color: hotpink;
}

.list-group-item {
    background-color: #EEEEEE;
    padding: 1vh 4vw;
}

/* .list-group-item {
    display: flex;
    flex-direction: row;
    /* align-items: flex-end; */
    /* width: 200px; */
/* }
.list-group-item>span {
    justify-self: right;
}

span {
    justify-self: right;
} */