/* input,
button {
    border: none;
    background-color: brown;
} */

/* .btn-secondary {
    border: none;
} */

/* input::-moz-focus-inner, input::-moz-focus-outer {
    border: none;
} */

#dog {
    /* width:1vw; */
    height: 100px;
}